import React, { useState, useEffect, isActive } from 'react';
import { useMenuContext } from './m_menucontext';
import { animated, useSpring, useTrail } from 'react-spring';
import ContactCard from './mobile_contactForm'; // Import the ContactCard component
import InstagramHandles from './ig_handles'; // Import the InstagramHandles component
import MobileFancyCard from './m_bio';
import CreationsSection from './video_card';
import handleCloseClick from './closing_logic_list'; // Import the handleCloseClick function


const MobileMenu = () => {
    const { isMenuOpen, setIsMenuOpen } = useMenuContext();
    const [isClosing, setIsClosing] = useState(false);
    const [activeItem, setActiveItem] = useState(null); // Track the active section
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [showSocials, setShowSocials] = useState(false);
    const [showFancyCard, setShowFancyCard] = useState(false);
    const [showCreations, setShowCreations] = useState(false);
    const [isActive, setIsActive] = useState(false);

// Function to toggle CreationsSection visibility
    const toggleCreations = () => {
      setIsActive(!isActive);
    };


    // Function to handle opening the MobileFancyCard component

    useEffect(() => {
      const event = new CustomEvent('creationsActive', { detail: { isActive: showCreations } });
      window.dispatchEvent(event);
    }, [showCreations]);
    
    


    const handleCreationsClick = () => {
        if (activeItem === 'creations') {
            // If Creations is active, close it
            setActiveItem(null);
            setShowCreations(false);
        } else {
            setActiveItem('creations');
            setShowCreations(true);
            setIsContactOpen(false);
            setShowSocials(false);
            setShowFancyCard(false);
        }
    };
    
      

    const handleBiographyClick = () => {
        setActiveItem('biography');
        setShowFancyCard(true);
        setIsContactOpen(false);
        setShowSocials(false);
        setShowCreations(false);
    };

    const handleCreationsClose = () => {
        setActiveItem(null);
        setShowCreations(false);
        setIsMenuOpen(false);
        setIsContactOpen(false);
        setShowSocials(false);
        setShowFancyCard(false);
    };
      

      // Function to handle item click and toggle its state
  const handleItemClick = (item) => {
    if (activeItem === item) {
      // If the clicked item is already active, close it
      setActiveItem(null);
    } else {
      // Otherwise, set it as active
      setActiveItem(item);
    }
    };    
    
    const items = [
        <button 
                style={{
                    backgroundColor: 'transparent',  // Remove background
                    fontFamily: 'inherit',  // Use inherited font-family instead of Roboto
                    fontSize: 'inherit',  // Use inherited font-size instead of 18px
                    fontWeight: 'inherit',  // Use inherited font-weight instead of bold
                    color: '#afafaf',  // Change color to #afafaf
                    border: 'none',  // Remove border
                    padding: '0',  // Remove padding
                }} 
                className="bio-button" 
                onClick={handleBiographyClick}
            >
                Biography
            </button>,
        <button style={{
          backgroundColor: 'transparent',  // Remove background
          fontFamily: 'inherit',  // Use inherited font-family instead of Roboto
          fontSize: 'inherit',  // Use inherited font-size instead of 18px
          fontWeight: 'inherit',  // Use inherited font-weight instead of bold
          color: '#afafaf',  // Change color to #afafaf
          border: 'none',  // Remove border
          padding: '0',  // Remove padding
      }} className="creations-button" onClick={handleCreationsClick}>Creations</button>,
        'Socials',
        <a href="https://soundcloud.com/trojohnofficial" target="_blank" rel="noopener noreferrer">Music</a>,
        'Contact'
    ];

    const fadeSpring = useSpring({
        opacity: (isMenuOpen || isClosing) && !isContactOpen ? 1 : 0,
        from: { opacity: 0 },
        config: { tension: 800, friction: 35 },
        reverse: isClosing,
        onRest: () => {
            if (isClosing) setIsClosing(false);
        }
    });

    const slideDownSpring = useSpring({
        transform: showCreations ? 'translateY(0%)' : 'translateY(-100%)',
        from: { transform: 'translateY(-100%)' },
        config: { tension: 280, friction: 38 }
      });
      

    const slideSpring = useSpring({
        transform: (isMenuOpen || isClosing) && !isContactOpen ? 'translateY(0%)' : 'translateY(-100%)',
        from: { transform: 'translateY(-100%)' },
        config: isClosing
            ? { tension: 80, friction: 35 }
            : { tension: 280, friction: 38 },
        reverse: isClosing
    });

    const trail = useTrail(items.length, {
        from: {
            opacity: 0,
            transform: 'translateY(-10%)'
        },
        to: {
            opacity: (isMenuOpen || isClosing) && !isContactOpen ? 1 : 0,
            transform: (isMenuOpen || isClosing) && !isContactOpen ? 'translateY(0%)' : 'translateY(-10%)'
        },
        config: {
            tension: 300,
            friction: 20
        },
        reverse: isClosing
    });

    const handleToggle = () => {
        if (isMenuOpen) {
          setIsClosing(true);
          setTimeout(() => {
            setIsMenuOpen(false);
            setIsContactOpen(false);
            setShowSocials(false);
            if (showCreations) {
              // Close the Creations section by calling handleCloseClick
              handleCloseClick();
            }
          }, 400);
        } else {
          setIsMenuOpen(true);
          setShowSocials(false);
        }
      };

    // Function to handle opening the ContactCard
    const handleContactOpen = () => {
        setIsContactOpen(true);
        setShowSocials(false);
    };

    // Function to handle closing the ContactCard
    const handleContactClose = () => {
        setIsContactOpen(false);
    };

    // Function to handle opening the InstagramHandles component
    const handleSocialsClick = () => {
        setShowSocials(true);
        setIsContactOpen(false);
    };

    return (
        <div className="mobilemenu">
            <button
            className={`mobilemenu-toggle ${isMenuOpen ? 'active' : ''}`}
            style={{ display: showCreations ? 'none' : 'block' }}
            onClick={handleToggle}
            />
          <animated.ul
            style={{ ...fadeSpring, ...slideSpring }}
            className={`mobilemenu-list ${isMenuOpen ? 'open' : ''} ${isClosing ? 'closing' : ''}`}
          >
            {trail.map((props, index) => (
              <animated.li style={props} key={items[index]}>
                {typeof items[index] === 'string' ? (
                  items[index] === 'Contact' ? (
                    <button style={{ paddingRight: '50px' }} className="contact-button" onClick={handleContactOpen}>Contact</button>
                  ) : items[index] === 'Socials' ? (
                    <button
                      style={{
                        color: '#afafaf',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                        padding: '0',
                      }}
                      onClick={handleSocialsClick}
                    >
                      Socials
                    </button>
                  ) : items[index] === 'Creations' ? (
                    <button className={`creations-button ${showCreations ? 'active' : ''}`} onClick={handleCreationsClick}>
                      {showCreations ? 'Creations -' : 'Creations +'}
                    </button>
                  ) : (
                    items[index]
                  )
                ) : (
                  items[index]
                )}
              </animated.li>
            ))}
          </animated.ul>
      
          {/* Render the ContactCard if isContactOpen is true */}
          {isContactOpen && (
            <div className="contact-card-container">
              <ContactCard onClose={handleContactClose} />
            </div>
          )}
      
          {/* Render the InstagramHandles component if showSocials is true */}
          {showSocials && <InstagramHandles />}
      
          {/* Render the MobileFancyCard if showFancyCard is true */}
          {showFancyCard && (
            <div className="fancy-card-container">
              <MobileFancyCard onClose={() => setShowFancyCard(false)} isOpen={showFancyCard} />
            </div>
          )}
      
            {/* Render the Creations section if showCreations is true */}
            {showCreations && (
            <animated.div style={slideDownSpring} className="creations-section">
                <CreationsSection onClose={handleCreationsClose} closeMenu={handleCloseClick} />
            </animated.div>
            )}
        </div>
      );
      
    
};

export default MobileMenu;
