import React from 'react';

const MobileFancyCard = ({ isOpen, onClose }) => {
  return (
    <div className={`m-fancy-card ${isOpen ? 'open' : 'close'}`}>
      <img
        src="/assets/images/trojohn-logo_trans.png"
        alt="Profile"
        className="m-profile-pic"
      />
      <div className="m-card-content">
        <p className="bio">
          is a Chicago-based production ensemble with a focus on crafting auditory and visual narratives that defy convention. Our sonic landscapes—inspired by dark, dystopian themes—mesh seamlessly with groundbreaking visual storytelling. Specializing in hip-hop and electronic music genres, we employ heavy bass, intricate percussion, and immersive soundscapes to construct unique auditory experiences. Our expertise extends to cutting-edge visual and animated productions across diverse industries. TROJOHN is not merely a brand; it's an immersive experience that continually redefines boundaries.
        </p>
      </div>
      <button className="m-close-button" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default MobileFancyCard;
