import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import FancyCard from './components/bio'; // Using the FancyCard component
import Menu from './components/menu'; // Assuming Menu is in the components directory
import Footer from './components/footer'; 
import VideoOverlay from './components/videoOverlay';
import LogoAnimated from './mobile-components/mobile_logo';
import MobileMenu from './mobile-components/mobile-menu';
import { MenuProvider } from './mobile-components/m_menucontext';
import useLogoBlur  from './mobile-components/useLogoBlur';
import MobileFooter from './mobile-components/mobile-footer';


function Logo() {
    const [hovered, setHovered] = useState(false);
    const [showBio, setShowBio] = useState(false);
    const [closing, setClosing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const [isMenuActive, setMenuActive] = useState(false);

    const handleMenuToggle = (active) => {
        setMenuActive(active);
    };
    
    useLogoBlur(isMenuActive); 

    // Handle mobile detection
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
      };

      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const openBio = () => {
        setShowBio(true);
    };

    const closeBioAfterAnimation = () => {
        setTimeout(() => {
            setShowBio(false);
            setClosing(false);
        }, 500);
    };

    const closeBio = () => {
        setClosing(true);
        closeBioAfterAnimation();
    };

    const handleBioClick = () => {
        if (showBio) {
            setClosing(true);
            setTimeout(() => {
                setShowBio(false);
                setClosing(false);
            }, 500);
        } else {
            setShowBio(true);
        }
    };

    useEffect(() => {
        if(!isMobile) {
            const video = document.getElementById('video1');
            const canvas = document.getElementById('videoCanvas');
            const ctx = canvas.getContext('2d');

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            video.addEventListener('play', () => {
                function drawVideo() {
                    if (video.paused || video.ended) {
                        return;
                    }
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    requestAnimationFrame(drawVideo);
                }
                drawVideo();
            });

            video.play();

            return () => {
                video.removeEventListener('play', drawVideo);
            }
        }
    }, [isMobile]);

    if (isMobile) {
        return (
            <MenuProvider>
                <LogoAnimated />
                <MobileMenu />
                <MobileFooter />
            </MenuProvider>

        );
    }
    

    return (
        <div className="logo-bio-container">
            
            <div 
                className="logo-container" 
                onMouseEnter={() => setHovered(true)} 
                onMouseLeave={() => setHovered(false)}
            >
                <img 
                    src="/assets/images/trojohn-logo_trans.png" 
                    alt="Trojohn Logo" 
                    style={{filter: hovered ? 'brightness(0)' : 'brightness(1)'}}
                />
            </div>
            
            <div>
            <Menu onBioClick={handleBioClick} onMenuToggle={handleMenuToggle} />

            {showBio && (
                <FancyCard 
                    isOpen={showBio}
                    onClose={handleBioClick}
                    className={closing ? 'closing' : ''} 
                    onAnimationEnd={() => setClosing(false)}
                />
            )}
              </div>
              
            <div>
                <VideoOverlay />
                </div>     
 
            <Footer />
        </div>
    );
}

// Render the Logo component in its respective container
const root = ReactDOM.createRoot(document.getElementById('logo-root'));
root.render(<Logo />);
