import React, { useEffect, useState } from 'react';

const InstagramHandles = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  const handleStyle = {
    textDecoration: 'none', // Remove underline
    color: '#afafaf', // Text color
    fontSize: '16px', // Adjust text size
  };

  return (
    <div className={`instagram-handles ${isAnimated ? 'animate' : ''}`}>
      <h2 className="instagram-handle">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Instagram_logo.png/1200px-Instagram_logo.png"
          alt="Instagram Logo"
          style={{ width: '24px', height: '24px', marginRight: '8px' }}
        />
        <a
          href="https://www.instagram.com/TrojohnOfficial/"
          target="_blank"
          rel="noopener noreferrer"
          style={handleStyle}
        >
          @TrojohnOfficial
        </a>
      </h2>
      <h2 className="instagram-handle">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Instagram_logo.png/1200px-Instagram_logo.png"
          alt="Instagram Logo"
          style={{ width: '24px', height: '24px', marginRight: '8px' }}
        />
        <a
          href="https://www.instagram.com/_Unfrended/"
          target="_blank"
          rel="noopener noreferrer"
          style={handleStyle}
        >
          @_Unfrended
        </a>
      </h2>
      <h2 className="instagram-handle">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Instagram_logo.png/1200px-Instagram_logo.png"
          alt="Instagram Logo"
          style={{ width: '24px', height: '24px', marginRight: '8px' }}
        />
        <a
          href="https://www.instagram.com/johnnymak89/"
          target="_blank"
          rel="noopener noreferrer"
          style={handleStyle}
        >
          @johnnymak89
        </a>
      </h2>
    </div>
  );
};

export default InstagramHandles;
