import React, { useState } from 'react';

const Menu = ({ onBioClick }) => {
    const [showSocials, setShowSocials] = useState(false);
    const [closing, setClosing] = useState(false);

    const handleBioClick = () => {
        if (closing) {
            return;
        }
        onBioClick();
    };

    const handleSocialsClick = () => {
        if (closing) {
            return;
        }
        setShowSocials(!showSocials);
        setClosing(true);

        setTimeout(() => {
            setClosing(false);
        }, 500);
    };

    const GooeyFilter = () => (
        <svg style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}>
            <defs>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="
                        1 0 0 0 0
                        0 1 0 0 0
                        0 0 1 0 0
                        0 0 0 18 -7" result="goo" />
                    <feBlend in="SourceGraphic" in2="goo" />
                </filter>
            </defs>
        </svg>
    );
    

    return (
        <>
            <GooeyFilter />
            <div className={`menu-container ${closing ? 'closing' : ''}`} style={{ backgroundColor: 'transparent' }}>
                <button onClick={handleBioClick} style={{ marginRight: '20px' }}>Biography</button>
                <button onClick={() => window.open("https://soundcloud.com/trojohnofficial", "_blank")} style={{ marginRight: '20px' }}>Music</button>
                <button onClick={handleSocialsClick}>Socials</button>
    
                {showSocials && (
                    <div className="socials-container">
                        <a href="https://www.instagram.com/trojohnofficial/" target="_blank" rel="noopener noreferrer" className="social-item" style={{textDecoration: 'none', color: 'inherit' }}>
                            <img src="/assets/images/instagram.png" alt="Instagram 1" className="socials-icon" />
                            <p>@TrojohnOfficial</p>
                        </a>
                        <a href="https://www.instagram.com/_unfrended/" target="_blank" rel="noopener noreferrer" className="social-item" style={{textDecoration: 'none', color: 'inherit' }}>
                            <img src="/assets/images/instagram.png" alt="Instagram 2" className="socials-icon" />
                            <p>@_Unfrended</p>
                        </a>
                    </div>
                )}
            </div>
        </>
    );
    
    
}

export default Menu;
