import React, { useState, useEffect } from 'react';
import ProjectDetails from './swipeable_logic'; // Import the ProjectDetails component
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);


// Assume these are your start and end shapes
// Circle Path
const startShape = "M10,10 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0";

// Square Path
const endShape = "M10,10 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0";


const CreationsSection = ({ isActive, onClose }) => {
  const [selectedItem, setSelectedItem] = useState('Subtronics');
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [previousProjectIndex, setPreviousProjectIndex] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);


  useEffect(() => {
    artistsProjects[selectedItem].forEach((_, index) => {
      gsap.to(`#page-dot-${index}`, {
        morphSVG: currentProjectIndex === index ? endShape : startShape,
        fill: currentProjectIndex === index ? '#FF5733' : '#FFFFFF',
        duration: 0.3,
      });
    });
  }, [currentProjectIndex, selectedItem]);
  
  
  

  const artistsProjects = {
    Subtronics: [
      {
        title: 'Subtronics - Astroid W/ Excision',
        videoSource: 'assets/videos/Astroid_promo.mp4',
        tour: null,
        role: 'Rigging, Environment building, Animation & Texture Refactoring',
        softwareUsed: 'Unreal Engine, Blender, Substance Painter',
      },
      {
        title: 'Antifractal',
        videoSource: 'assets/videos/Subtronics_showreel1.mp4',
        tour: 'Antifractal 2023',
        role: 'Rigging, Environment building, Animation & Texture Refactoring',
        softwareUsed: 'Unreal Engine, Blender, Substance Painter',
      },
    ],
    'Spag Heddy': [
      {
        title: 'The Noodle Effect',
        videoSource: '',
        tour: null,
        role: 'Rigging, Environment building, Animation & Texture Creation',
        softwareUsed: 'Unreal Engine, Blender, Substance Painter',
      },
    ],
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setCurrentProjectIndex(0); // Reset the project index when switching artists
  
    // Remove the "selected-artist" class from all list items
    const listItems = document.querySelectorAll('.creation-list li');
    listItems.forEach((li) => {
      li.classList.remove('selected-artist');
    });
  
    // Add the "selected-artist" class to the clicked list item
    const selectedListItem = document.querySelector(`.creation-list li[data-artist="${item}"]`);
    if (selectedListItem) {
      selectedListItem.classList.add('selected-artist');
    }
  };

  const handleSwipeLeft = () => {
    if (currentProjectIndex < artistsProjects[selectedItem].length - 1) {
      setSwipeDirection('left');
      setPreviousProjectIndex(currentProjectIndex);
      setTimeout(() => setCurrentProjectIndex(currentProjectIndex + 1), 300);
    }
  };
  
  const handleSwipeRight = () => {
    if (currentProjectIndex > 0) {
      setSwipeDirection('right');
      setPreviousProjectIndex(currentProjectIndex);
      setTimeout(() => setCurrentProjectIndex(currentProjectIndex - 1), 300);
    }
  };
  

  

  const handleCloseClick = () => {
    setSelectedItem(null);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={`creations-section ${isActive ? 'active' : ''}`} style={{ filter: isActive ? 'blur(5px)' : 'none' }}>
      {selectedItem && (
        <div className="project-container">
          <ProjectDetails
            project={artistsProjects[selectedItem][currentProjectIndex]}
            onClose={handleCloseClick}
            currentProjectIndex={currentProjectIndex}
            previousProjectIndex={previousProjectIndex}
            swipeDirection={swipeDirection}
            onSwipeLeft={handleSwipeLeft}
            onSwipeRight={handleSwipeRight}
          />
        <div className="page-dots">
          {artistsProjects[selectedItem].map((_, index) => (
            <svg
              key={index}
              width="10"
              height="10"
              className={`page-dot ${currentProjectIndex === index ? 'active' : ''}`}
            >
              <path
                id={`page-dot-${index}`}
                d={currentProjectIndex === index ? endShape : startShape}
                fill={currentProjectIndex === index ? '#FF5733' : '#FFFFFF'}
              />
            </svg>
          ))}
        </div>

        </div>
      )}
      <ul className="creation-list">
        <li
          className={selectedItem === 'Subtronics' ? 'selected-artist' : ''}
          onClick={() => handleItemClick('Subtronics')}
        >
          Subtronics
        </li>
        <li
          className={selectedItem === 'Spag Heddy' ? 'selected-artist' : ''}
          onClick={() => handleItemClick('Spag Heddy')}
        >
          Spag Heddy
        </li>
      </ul>
    </div>
);


};

export default CreationsSection;
