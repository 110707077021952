import React, { useState, useEffect, useRef } from 'react';

const VideoOverlay = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [activeProjectIndex, setActiveProjectIndex] = useState(0); // Starts with the first project

  const projects = [
    {
      logoSrc: `${process.env.PUBLIC_URL}/assets/images/Subtronics_logo.png`,
      videoSrc: "assets/videos/Subtronics_showreel1.mp4",
      tourInfo: "ANTIFRACTAL TOUR '23",
      softwareUsed: "Unreal Engine, Blender, Adobe After Effects",
      responsibilities: "Rigging, Texture Refactoring, Procedural Structures, Animating",
      mainCollaboration: "Immense gratitude to Ray Elemento for the continuous collaboration and shared vision on this project for Subtronics' latest tour."
    },
    {
      logoSrc: `${process.env.PUBLIC_URL}/assets/images/Subtronics_logo.png`,
      videoSrc: "assets/videos/Astroid_promo.mp4",
      tourInfo: "ASTROID W/ EXCISION",
      softwareUsed: "Unreal Engine, Blender, Substance Painter",
      responsibilities: "Animation, Rigging, Environment Building",

    }
  ];

  const getVideoStyle = (index) => {
    if (index === 1) { // For the second video
      return {
        width: '80%',  // Adjust as needed
        height: 'auto',
        padding: '0 10%',
        // ... any other styles you want to apply

      };
    }
  
    // Default styles for other videos
    return {};
  };

  useEffect(() => {
    const videoElement = videoRef.current;
  
    const onVideoEnded = () => {
      setIsVisible(false);
  
      setTimeout(() => {
        setActiveProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
      }, 10000); // Rest for 10 seconds
    };
  
    const onActiveProjectChanged = () => {
      setIsVisible(true);
      videoElement.play().catch(error => {
        console.error("Video play failed:", error);
      });
    };
  
    videoElement.addEventListener('ended', onVideoEnded);
    
    // Play the new video when the active project changes
    onActiveProjectChanged();
  
    return () => {
      videoElement.removeEventListener('ended', onVideoEnded);
    };
  }, [activeProjectIndex]); // <--- Add activeProjectIndex to the dependency array
  

  const currentProject = projects[activeProjectIndex];

  return (
    <div className="video-overlay" style={{ opacity: isVisible ? 1 : 0 }}>
      <img className="subtronics-logo" src={currentProject.logoSrc} alt="Logo" />
      <span className="tour-info">{currentProject.tourInfo}</span>
      <video
        key={currentProject.videoSrc}
        ref={videoRef}
        autoPlay
        muted
        controls
        style={getVideoStyle(activeProjectIndex)} // Apply the style
      >
        <source src={currentProject.videoSrc} type="video/mp4" />
        </video>



      <div className="video-info-card">
        <h4>Project Overview</h4>
        <ul>
          <li><strong>Softwares Utilized:</strong> {currentProject.softwareUsed}</li>
          <li><strong>Responsibilities:</strong> {currentProject.responsibilities}</li>
          {currentProject.mainCollaboration && <li><strong>Main Collaboration:</strong> {currentProject.mainCollaboration}</li>}
          {currentProject.collaborators && (
            <>
              <li><strong>Collaborators:</strong></li>
              {currentProject.collaborators.map(collab => (
                <li key={collab.name}><strong>{collab.role}:</strong> {collab.name}</li>
              ))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default VideoOverlay;
