
import { useEffect } from 'react';
import gsap from 'gsap';

const usePageDotMorphing = (previousIndex, currentProjectIndex, swipeDirection) => {
  useEffect(() => {
    const dots = document.querySelectorAll('.page-dot');

    // Ensure that startDot and endDot are correctly referencing SVG elements
    const startDot = dots[previousIndex];
    const endDot = dots[currentProjectIndex];

    if (!startDot || !endDot) {
      // Handle cases where startDot or endDot are not found
      return;
    }

    const startDotCX = startDot.getAttribute('cx');
    const startDotCY = startDot.getAttribute('cy');
    const endDotCX = endDot.getAttribute('cx');
    const endDotCY = endDot.getAttribute('cy');

    if (!startDotCX || !startDotCY || !endDotCX || !endDotCY) {
      // Handle cases where the required attributes are missing
      return;
    }

    // Create a timeline for the morphing animation
    const morphingTimeline = gsap.timeline();

    // Jelly-like effect: Use bezier curves to create a bouncing animation
    morphingTimeline.to(startDot, {
      duration: 0.2,
      x: parseFloat(startDotCX) + 10,
      y: parseFloat(startDotCY) - 20,
      ease: 'power3.out',
    });
    morphingTimeline.to(
      startDot,
      {
        duration: 0.2,
        x: parseFloat(startDotCX) - 10,
        y: parseFloat(startDotCY) + 20,
        ease: 'power3.out',
      },
      '+=0.2'
    );
    morphingTimeline.to(
      startDot,
      {
        duration: 0.2,
        x: parseFloat(startDotCX) + 5,
        y: parseFloat(startDotCY) - 10,
        ease: 'power3.out',
      },
      '+=0.2'
    );

    // Reset to the original position
    morphingTimeline.to(startDot, {
      duration: 0.1,
      x: parseFloat(startDotCX),
      y: parseFloat(startDotCY),
    });

    // Swipe direction logic for text animation
    const textElements = document.querySelectorAll('.text-class'); // Replace '.text-class' with the correct class or id
    const currentTextElement = textElements[currentProjectIndex];

    if (currentTextElement) {
      const textTransitionTimeline = gsap.timeline();

      if (swipeDirection === 'left') {
        textTransitionTimeline.fromTo(
          currentTextElement,
          { x: 300, opacity: 0 },
          { x: 0, opacity: 1, duration: 0.5 }
        );
      } else {
        textTransitionTimeline.fromTo(
          currentTextElement,
          { x: -300, opacity: 0 },
          { x: 0, opacity: 1, duration: 0.5 }
        );
      }
    }

    // Reset other dots to default
    dots.forEach((dot, index) => {
      if (index !== previousIndex && index !== currentProjectIndex) {
        dot.style.transform = '';
      }
    });

  }, [previousIndex, currentProjectIndex, swipeDirection]);
};

export default usePageDotMorphing;
