import React, { useState, useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { useMenuContext } from './m_menucontext';

const LogoAnimated = () => {
    const { isMenuOpen } = useMenuContext();
    const [isGrainy, setIsGrainy] = useState(false);
    const container = useRef(null);
    const animationInstance = useRef(null);

    useEffect(() => {
        if (animationInstance.current) {
            animationInstance.current.destroy();
        }

        animationInstance.current = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: 'assets/lottie/loader-linear-eye.json'
        });

        const toggleGrainy = () => setIsGrainy(!isGrainy);

        const grainyOn = setTimeout(() => toggleGrainy(), 10000);
        const grainyOff = setTimeout(() => toggleGrainy(), 30000);

        return () => {
            clearTimeout(grainyOn);
            clearTimeout(grainyOff);
            animationInstance.current.destroy();
        };
    }, [isGrainy]);

    return (
        <div className={`logo-container ${isGrainy ? 'grainy' : ''}`}>
            <div ref={container}></div>
            <img src={`${process.env.PUBLIC_URL}/assets/images/trojohn-logo_trans.png`} 
                 alt="Logo" 
                 className={`logo ${isGrainy ? 'grainy' : ''} ${isMenuOpen ? 'blurred' : ''}`} />
        </div>
    );
};

export default LogoAnimated;
