import React, { useEffect, useState } from 'react';

const ContactCard = ({ onClose, closeButtonClass }) => {
  const [isAnimate, setIsAnimate] = useState(false);

  useEffect(() => {
    setIsAnimate(true);
    const animationTimeout = setTimeout(() => setIsAnimate(false), 2000);

    return () => clearTimeout(animationTimeout);
  }, []);

  const closeButtonStyle = {
    backgroundColor: '#464646', // Dark gun metal silver background
    color: '#ffffff', // White text
    borderRadius: '25px', // 25px corner radius
    padding: '5px 20px', // Padding adjusted for a rectangle shape
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
    border: '2px solid #464646', // 2px red outline
    margin: '20px', // 20px margin
  };

  return (
    <div className={`card ${isAnimate ? 'animate' : ''}`}>
      <h3 className="card-title">Contact</h3>
      <p className="card-description">Get in contact with us:</p>
      <div className="card-buttons">
        <button className="card-button" onClick={() => window.location.href = 'mailto:connect@trojohn.com'}>
          Connect
        </button>
        {/* Apply closeButtonClass directly to the "Close" button */}
        <button style={closeButtonStyle} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ContactCard;
