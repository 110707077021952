// useLogoBlur.js
import { useEffect } from 'react';

const useLogoBlur = (isMenuActive) => {
    useEffect(() => {
        const logoElement = document.querySelector('.logo-container img');
        if (isMenuActive) {
            logoElement.classList.add('blurred');
        } else {
            logoElement.classList.remove('blurred');
        }
    }, [isMenuActive]);
};

export default useLogoBlur;
