import React from 'react';
import { useMenuContext } from './m_menucontext';

const MobileFooter = () => {
  const { isMenuOpen } = useMenuContext();
  
  return (
      <footer className={`m_footer ${isMenuOpen ? 'blurred' : ''}`}>
          <div className="content">
              <div className="company-name">TROJOHN LLC</div>
              <div className="copyright">
                  &copy; {new Date().getFullYear()} All rights reserved
              </div>
          </div>
      </footer>
  );
};


  
  export default MobileFooter;