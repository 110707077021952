import React, { useState, useRef, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import usePageDotMorphing from '../animation_logic/f-swiping'
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);


const ProjectDetails = ({ project, currentProjectIndex, previousProjectIndex, swipeDirection, onClose, onSwipeLeft, onSwipeRight }) => {
  usePageDotMorphing(previousProjectIndex, currentProjectIndex, swipeDirection);

  const animationClass = swipeDirection === 'left' ? 'slide-in-left' : 'slide-in-right';
  
  


  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (onSwipeLeft) {
        onSwipeLeft();
      }
    },
    onSwipedRight: () => {
      if (onSwipeRight) {
        onSwipeRight();
      }
    },
  });

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [project.videoSource]);

  return (
    <>
      <div {...handlers} className={`project-details ${animationClass}`}>
        <div className="embedded-video-wrapper">
          <video ref={videoRef} controls playsInline className="embedded-video">
            <source src={project.videoSource} type="video/mp4" />
          </video>
        </div>
        <div className={`overview ${animationClass}`}>
          <p>
            <strong>Project:</strong> {project.title}
          </p>
          {project.tour && (
            <p>
              <strong>Tour:</strong> {project.tour}
            </p>
          )}
          <p>
            <strong>Role:</strong> {project.role}
          </p>
          <p>
            <strong>Software Used:</strong> {project.softwareUsed}
          </p>
        </div>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  );
};

export default ProjectDetails;
